<template>
  <div>
    <d-body-top-bar :title="subscriptionTitle">
      <div slot="tree">
        <label class="inactive-tree">
          {{ $t('views.subscriptions.subscriptions-list') }} /
          <label class="inactive-tree link-area pointer" @click="$router.push({name: 'subscriptions'})">
            {{ $t('views.subscriptions.all-subscriptions') }}
          </label>
          /
        </label>
        <label class="bg-doinsport-primary ml-1">
          {{ subscriptionTitle }}
        </label>
      </div>
    </d-body-top-bar>
    <div
      v-if="isLoaded"
      class="container-fluid subscriptions-card-new-container"
    >
      <b-card class="subscriptions-card-new">
        <validation-observer
          ref="subscriptionObserver"
          slim
        >
          <b-row
            v-if="isDisabled"
            class="mb-2 mt-2"
          >
            <b-col class="light-danger-col-title" cols="12">
              <span>
                <i class="icofont icofont-exclamation-tringle"></i>
              </span>
              {{ networkDescription }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              :class="widthCheck (1100) ? 'col-divider-5': ''"
              :cols="widthCheck (1100) ? '' : 12"
            >
              <subscription-plan-information
                :subscription-plan="subscriptionPlan"
                :validator="validator"
                :violations="violations"
                :is-disabled="isDisabled"
              />
            </b-col>
            <div
              v-if="widthCheck (1100)"
              class="split-layout__divider"
            >
              <div class="split-layout__rule"></div>
              <div class="split-layout__rule"></div>
            </div>
            <b-col :cols="widthCheck (1100) ? 6 : 12">
              <subscription-plan-variations
                :subscription-plan="subscriptionPlan"
                :validator="validator"
                :violations="violations"
                :is-disabled="isDisabled"
                :is-disabled-prices="isDisabledPrices"
                @on:recent-docs:updated="recentDocs = $event"
              />
            </b-col>
          </b-row>
        </validation-observer>
        <b-row>
          <b-col align="right">
            <d-button
              :text="isDisabledPrices ? 'general.actions.previous' : 'general.actions.save'"
              class="d-btn-sm font-text-title btn d-btn-danger font-text-title"
              @on:button-click="onClick"
            />
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>
<script>
import SubscriptionPlan from "@/classes/doinsport/SubscriptionPlan";
import {
  getSubscriptionPlan,
  postSubscriptionPlan,
  putSubscriptionPlan, URI_SUBSCRIPTION_PLAN
} from "@api/doinsport/services/subscription-plans/subscription-plans.api";
import {cloneData, fromIdToIriReference, hydrate} from "@/utils/form";
import {DANGER, SUCCESS} from "@plugins/flash";
import {
  getSubscriptionPlanPrices,
  postSubscriptionPlanPrice,
  putSubscriptionPlanPrice
} from "@api/doinsport/services/subscription-plans/subscription-plan-price/subscription-plan-price";
import {ROLE_CLUB_NETWORK_OWNER} from "@/utils/role";
import {ALL_NETWORK_CLUB_LIST, NETWORK_CLUB_SELECTION, ONLY_CURRENT_CLUB} from "@/constants/network/network-sharing";

export default {
  data: () => ({
    mode: 'create',
    violations: [],
    recentDocs: [],
    isLoaded: false,
    validator: true,
    subscriptionPlan: new SubscriptionPlan(),
  }),
  components: {
    SubscriptionPlanInformation: () => import('./subscription-informations/Index'),
    SubscriptionPlanVariations: () => import('./subscription-variations/Index')
  },
  computed: {
    isDisabledPrices() {
      if (this.isDisabled) {
        if (!this.hasNetworkRole) {
          return !this.subscriptionPlan.networkEditable;
        }
      }

      return this.isDisabled;
    },
    currentClub() {
      return this.$store.getters['auth/currentClub'];
    },
    subscriptionTitle() {
      return this.subscriptionPlan.id ? this.$t('views.subscriptions.new.edit-subscription') : this.$t('views.subscriptions.new.add-subscription');
    },
    hasNetworkRole() {
      return this.$hasRole(ROLE_CLUB_NETWORK_OWNER);
    },
    networkDescription() {
      const userDescription = this.$t('views.subscriptions.is-disabled-description');
      const networkDescription = this.$t('views.subscriptions.is-network-description', {club: this.subscriptionPlan.club.name});

      return this.hasNetworkRole ? networkDescription : userDescription;
    },
    isDisabled() {
      if (this.subscriptionPlan.id) {
        switch (this.subscriptionPlan.networkSharingFor) {
          case ONLY_CURRENT_CLUB:
            return false;
          case NETWORK_CLUB_SELECTION:
          case ALL_NETWORK_CLUB_LIST:
            if (!this.hasNetworkRole) {
              return true;
            } else {
              if (this.currentClub['@id'] !== this.subscriptionPlan.club['@id']) {
                return true;
              }
            }
        }
      }

      return false;
    },
  },
  methods: {
    newSubscriptionPlan() {
      const serializedSubscriptionPlan = new SubscriptionPlan(this.subscriptionPlan, {cast: true});
      const prices = cloneData(serializedSubscriptionPlan.prices);

      if (!this.hasNetworkRole) {
        delete serializedSubscriptionPlan.networkSharingFor;
        delete serializedSubscriptionPlan.networkSharingSelectedClubs;
        delete serializedSubscriptionPlan.networkEditable;
      }

      delete serializedSubscriptionPlan.prices;

      postSubscriptionPlan(serializedSubscriptionPlan)
        .then((response) => {
          const requests = [];

          for (const price of prices) {
            price.plan = response.data['@id'];
            requests.push(postSubscriptionPlanPrice(price));
          }

          for (const doc of this.recentDocs) {
            const payload = {
              entry: 'plan',
              name: doc.name,
              url: response.data['@id'],
              target: 'subscriptionPlanDocument'
            };

            requests.push(this.$uploadFile(doc.contentUrl, payload));
          }

          Promise.all(requests)
            .finally(() => {
              this.$flash(null, this.$t('general.actions.success-update'), 5000, SUCCESS);
              this.$router.replace({name: 'subscriptions'});
            })
          ;
        })
      ;
    },
    updateSubscriptionPlan() {
      const serializedSubscriptionPlan = new SubscriptionPlan(this.subscriptionPlan, {cast: true});

      const prices = cloneData(serializedSubscriptionPlan.prices)

      if (!this.hasNetworkRole) {
        delete serializedSubscriptionPlan.networkSharingFor;
        delete serializedSubscriptionPlan.networkSharingSelectedClubs;
        delete serializedSubscriptionPlan.networkEditable;
      }

      delete serializedSubscriptionPlan.prices;
      delete serializedSubscriptionPlan.club;

      this.getPutSubscriptionRequest(serializedSubscriptionPlan)
        .then((response) => {
          const requests = [];

          for (const price of prices) {
            price.plan = fromIdToIriReference(URI_SUBSCRIPTION_PLAN, this.subscriptionPlan.id);
            requests.push(price.id ? putSubscriptionPlanPrice(price.id, price) : postSubscriptionPlanPrice(price));
          }

          Promise.all(requests)
            .then((responses) => {
              this.$flash(null, this.$t('general.actions.success-update'), 5000, SUCCESS);
              this.$router.replace({name: 'subscriptions'})
            })
          ;
        })
      ;
    },
    getPutSubscriptionRequest(serializedSubscriptionPlan) {
      return !this.isDisabled ? putSubscriptionPlan(serializedSubscriptionPlan.id, serializedSubscriptionPlan) : Promise.resolve();
    },
    onClick() {
      if (this.isDisabledPrices) {
        this.$router.push({name: 'subscriptions'});
      } else {
        this.validator = !this.validator;
        try {
          this.$refs.subscriptionObserver.validate().then((valid) => {
            if (this.validitySubscriptionSelectionValidated()) {
              this.$flash(null, this.$t('views.subscriptions.new.notification.selection-validity-subscription-required'), 4000);
            } else if (this.isNetworkSubscriptionShareInvalid()) {
              this.$flash(null, this.$t('views.subscriptions.new.notification.selection-network-subscription-required'), 4000);
            } else {
              if (valid) {
                let verification = true;

                if (this.subscriptionPlan.fromDate === null && this.subscriptionPlan.toDate === null) {
                  if (this.subscriptionPlan.validityPeriodicityType !== null) {
                    for (const price of this.subscriptionPlan.prices) {
                      if (!price.dueDateGapType) {
                        verification = false;
                        break;
                      }
                    }
                    if (verification) {
                      if (this.subscriptionPlan.id === null) {
                        this.newSubscriptionPlan();
                      } else {
                        this.updateSubscriptionPlan();
                      }
                    }
                  }
                } else {
                  this.subscriptionPlan.automaticRenewal = false;
                  for (const price of this.subscriptionPlan.prices) {
                    if (!price.dueDateGapType) {
                      verification = false;
                      break;
                    }
                  }
                  if (verification) {
                    if (this.subscriptionPlan.id === null) {
                      this.newSubscriptionPlan();
                    } else {
                      this.updateSubscriptionPlan();
                    }
                  }
                }
              }
            }
          });
        } catch (e) {
        }
      }
    },
    isNetworkSubscriptionShareInvalid() {
      if (!this.hasNetworkRole) {
        return false;
      }

      return (this.subscriptionPlan.networkSharingFor === 'selection' && this.subscriptionPlan.networkSharingSelectedClubs.length === 0);
    },
    validitySubscriptionSelectionValidated() {
      return (
        this.subscriptionPlan.fromDate === null &&
        this.subscriptionPlan.toDate === null &&
        this.subscriptionPlan.validityDurationCount === null &&
        this.subscriptionPlan.validityPeriodicityType === null)
        ;
    },
    widthCheck(check) {
      return this.$store.getters['layout/getInnerWidth'] > check;
    }
  },
  created() {
    this.mode = this.$router.currentRoute.params.id ? 'update' : 'create';
  },
  mounted() {
    this.isLoaded = false;

    if (this.mode === 'update') {
      getSubscriptionPlan(this.$router.currentRoute.params.id)
        .then((response) => {
          const tmpSubscriptionPlanData = cloneData(response.data);
          getSubscriptionPlanPrices(tmpSubscriptionPlanData.id)
            .then((dataResponse) => {
              tmpSubscriptionPlanData.prices = dataResponse.data['hydra:member'];
              this.subscriptionPlan = new SubscriptionPlan(tmpSubscriptionPlanData, {deserialize: true});
              this.subscriptionPlan.maxDelayToAllowBookingCancellation = this.subscriptionPlan.maxDelayToAllowBookingCancellation / 3600;
              this.subscriptionPlan.minDelayToAllowBooking = this.subscriptionPlan.minDelayToAllowBooking / 3600;
              this.isLoaded = true;
            })
          ;

        })
    } else {
      this.isLoaded = true;
    }
  },
}
</script>
<style scoped>
.subscriptions-card-new .card-body {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding-top: .5rem;
  padding-bottom: 1.0rem;
  padding-left: 1.0rem;
  padding-right: 1.0rem;
}

@media only screen and (max-width: 1151px) {
  .subscriptions-card-new-container {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.subscriptions-card-new-container .card {
  box-shadow: 0px 10px 16px #E2E1E6;
  border-radius: 6px;
}

.col-divider-5 {
  -ms-flex: 0 0 48.666667%;
  -webkit-box-flex: 0;
  flex: 0 0 48.666667%;
  max-width: 48.666667%;
}
</style>
