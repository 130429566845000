import {_delete, _get, _post, _put, clubId} from "@api/doinsport/services/httpService";

const URI = "clubs/subscription-plans/prices";

export const deleteSubscriptionPlanPrice = (id) => _delete(URI + '/' + id);

export const postSubscriptionPlanPrice = (subscriptionPlanPrice) => _post(URI, subscriptionPlanPrice);

export const getSubscriptionPlanPrices = (subscriptionPlanId) => _get(
  URI +
  '?plan.id=' + subscriptionPlanId +
  '&club.id=' + clubId +
  '&page=1&itemsPerPage=100'
  )
;

export const getSubscriptionPlanPrice = (id) => _get(URI + '/' + id);

export const putSubscriptionPlanPrice = (id, data) => _put(URI + '/' + id, data);

